import React, { useRef, useEffect, Suspense, useState, forwardRef } from 'react';
import * as THREE from 'three';
import { Canvas, useLoader, useThree } from '@react-three/fiber';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { PerspectiveCamera } from '@react-three/drei';
import gsap from 'gsap';
import './App.css';

function calculateZPositionForSize(object, camera, targetSizeInPixels, renderer) {
  const box = new THREE.Box3().setFromObject(object);
  const size = box.getSize(new THREE.Vector3());
  const logoHeight = size.y;

  const vFOV = THREE.MathUtils.degToRad(camera.fov);
  const viewportHeight = renderer.domElement.clientHeight;

  const distance = (logoHeight / targetSizeInPixels) * (viewportHeight / (2 * Math.tan(vFOV / 2)));

  return distance;
}

function convertPixelsToWorldUnits(camera, pixelValue, viewportHeight) {
  const vFOV = THREE.MathUtils.degToRad(camera.fov);
  const worldHeightAtZ0 = 2 * Math.tan(vFOV / 2) * camera.position.z;
  const worldUnitsPerPixel = worldHeightAtZ0 / viewportHeight;
  return pixelValue * worldUnitsPerPixel;
}

const Model = forwardRef(({ url, newHeight, targetSizeInPixels, onAnimationEnd }, ref) => {
  const logoRef = useRef();
  const lineRef = useRef();
  const { camera, gl, viewport } = useThree();
  const [hasAnimated, setHasAnimated] = useState(false);
  const initialPositions = useRef({ logo: new THREE.Vector3(), line: new THREE.Vector3() });

  const materials = useLoader(MTLLoader, url.replace('.obj', '.mtl'));
  const obj = useLoader(OBJLoader, url, (loader) => {
    materials.preload();
    loader.setMaterials(materials);
  });

  useEffect(() => {
    if (logoRef.current && !hasAnimated) {
      const box = new THREE.Box3().setFromObject(logoRef.current);
      const center = box.getCenter(new THREE.Vector3());
      logoRef.current.position.sub(center);

      const size = box.getSize(new THREE.Vector3());
      const maxDim = Math.max(size.x, size.y, size.z);
      const initialScale = 2 / maxDim;
      logoRef.current.scale.set(initialScale, initialScale, initialScale);

      logoRef.current.position.set(-0.15, -0.55, 14);
      logoRef.current.rotation.y = -Math.PI / 2;

      initialPositions.current.logo.copy(logoRef.current.position);

      gsap.to(logoRef.current.rotation, {
        x: 0,
        y: 0,
        duration: 1.5,
        ease: "power2.inOut",
      });

      const newZPosition = calculateZPositionForSize(logoRef.current, camera, targetSizeInPixels, gl);
      gsap.to(camera.position, {
        z: newZPosition,
        duration: 1.5,
        ease: "power2.inOut",
      });

      gsap.to(logoRef.current.position, {
        x: 0,
        y: 0,
        z: 0,
        duration: 1.7,
        ease: "power2.inOut",
      });


    }
  }, [obj, hasAnimated, targetSizeInPixels, camera, gl, onAnimationEnd]);

  useEffect(() => {
    const handleResize = () => {
      if (logoRef.current && camera && lineRef.current) {
        const newZPosition = calculateZPositionForSize(logoRef.current, camera, targetSizeInPixels, gl);
        camera.position.z = newZPosition;

        const viewportHeight = gl.domElement.clientHeight;
        const logoInitialY = initialPositions.current.logo.y;
        const lineInitialY = initialPositions.current.line.y;

        const lineOffsetInPixels = 90;
        const lineOffsetInWorld = convertPixelsToWorldUnits(camera, lineOffsetInPixels, viewportHeight);

        const targetYForLine = lineOffsetInWorld - viewportHeight / 2 * convertPixelsToWorldUnits(camera, 1, viewportHeight);
        const targetYForLogo = targetYForLine + (convertPixelsToWorldUnits(camera, 90, viewportHeight) / 2);

        logoRef.current.position.y = logoInitialY - targetYForLogo + 1.3 + 0.55;
        lineRef.current.position.y = lineInitialY - targetYForLine + 0.4;

        const newLineScaleY = viewport.width * convertPixelsToWorldUnits(camera, 1, viewportHeight);
        lineRef.current.scale.y = newLineScaleY;
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [logoRef, camera, gl, targetSizeInPixels]);

  return (
    <>
      <primitive ref={logoRef} object={obj} />
      <mesh ref={lineRef} scale={[0, 1, 1]} visible={false}>
        <planeGeometry attach="geometry" args={[window.innerWidth, 0.01]} />
        <meshBasicMaterial attach="material" color="black" />
      </mesh>
    </>
  );
});

function Scene({ modelRef, newHeight, targetSizeInPixels, onAnimationEnd }) {
  return (
    <>
      <color attach="background" args={["white"]} />
      <PerspectiveCamera makeDefault position={[0, 0, 11]} />
      <ambientLight intensity={4} />
      <pointLight position={[1, 1, 1]} intensity={1} />
      <Suspense fallback={null}>
        <Model url="/logo.obj" ref={modelRef} newHeight={newHeight} targetSizeInPixels={targetSizeInPixels} onAnimationEnd={onAnimationEnd} />
      </Suspense>
    </>
  );
}

export default function Animation({ onAnimationEnd }) {
  const containerRef = useRef();
  const modelRef = useRef();
  const newHeight = 90;
  const targetSizeInPixels = 35;

  return (
    <div ref={containerRef} style={{ width: '100vw', height: '100vh', overflow: 'hidden', position: 'relative', marginTop: '0' }}>
      <Canvas resize={{ scroll: false, debounce: { scroll: 50, resize: 50 } }} onAnimationEnd={onAnimationEnd}>
        <Scene modelRef={modelRef} newHeight={newHeight} targetSizeInPixels={targetSizeInPixels} onAnimationEnd={onAnimationEnd} />
      </Canvas>
    </div>
  );
}
