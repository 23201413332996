import React, { useState, useEffect } from 'react';
import './App.css';
import Animation from './Animations.jsx';

export default function App() {
  const [showCanvas, setShowCanvas] = useState(true);
  const [isLogoLoaded, setIsLogoLoaded] = useState(false);
  const [logoSrc, setLogoSrc] = useState('');

  const handleAnimationEnd = () => {
    //setShowCanvas(false);
  };

  useEffect(() => {
    const logoImage = new Image();
    logoImage.src = './carscreen-logo.png';
    logoImage.onload = () => {
      setIsLogoLoaded(true);
      setLogoSrc(logoImage.src);
    };
  }, []);

  return (
    <div className="App">
      {showCanvas && <Animation onAnimationEnd={handleAnimationEnd} />}
    </div>
  );
}
